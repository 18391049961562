import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { setGiftWrapping } from "areas/cart/store/actions"

/**
 * C Design Gift Wraps
 *
 * @property {string} giftWrapPrice
 * @property {Action} handleGiftWrap
 * @property {Object} item
 * @property {Object} selectedWrap
 * @property {Object[]} pkgOptions
 */
export default function withGiftWraps(WrappedComponent) {
  const InternalGiftWraps = (props) => {
    const [selectedWrap, setWrap] = useState(props.itemWrap)

    useEffect(() => {
      setWrap(props.itemWrap)
    }, [props.itemWrap])

    return (
      <WrappedComponent
        handleGiftWrap={(p) => {
          setWrap(p.id === "NONE" ? null : p.package)
          if (typeof props.handleGiftWrap === "function") {
            props.handleGiftWrap(p)
          } else {
            props.selectGiftWrap(p.package)
          }
        }}
        selectedWrap={selectedWrap}
        {...props}
      />
    )
  }

  const mapStateToProps = (state, ownProps) => {
    const pkgOptions = ownProps.item.gift_package_options
    const itemWrap =
      ownProps.itemWrap ||
      ownProps.item?.addons?.find((a) => a.addon_type === "PACKAGING")
    return {
      giftWrapPrice: pkgOptions[0]?.package.price,
      itemWrap,
      pkgOptions,
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      selectGiftWrap: (pkg) => {
        dispatch(setGiftWrapping(ownProps.item.id, pkg, "cart"))
      },
    }
  }

  InternalGiftWraps.propTypes = {
    handleGiftWrap: PropTypes.func,
    itemWrap: PropTypes.object,
    selectGiftWrap: PropTypes.func,
    selectedWrap: PropTypes.object,
  }

  const GiftWraps = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalGiftWraps)

  return GiftWraps
}
