import { Box, Button, Image, Typography } from "@jewlr/storybook/core"
import { Checkbox } from "@jewlr/storybook/form"
import { FocusScope } from "@react-aria/focus"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import withGiftWraps from "areas/cart/hoc/with-gift-wraps"
import { to_currency } from "helpers/number"
import { useNavigation } from "helpers/use-navigation"

const PackageSlider = styled(Box)`
  display: flex;
  justify-content: flex-start;
  overflow: auto hidden;
  padding-bottom: 10px;
`
const PackageItem = styled(Button).attrs({
  variant: "text",
})`
  border: 2px solid
    ${(props) => (props.selected ? props.theme.colors.selected : "transparent")};
  border-radius: 8px;
  display: block;
  flex: 0 0 30vw;
  padding: 10px;
  text-align: center;
  ${(props) => props.theme.mediaQueries.tablet`
    flex: 0 0 135px;
  `}
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.primary};
    outline: none;
  }
  ${(props) =>
    props.selected &&
    `
    position: relative;
    &:after {
      bottom: -12px;
      content: url(${sl.config.jewlr_asset_url}images/icons/jewlr4-checkmark.svg);
      display: block;
      height: 22px;
      left: 50%;
      position: absolute;
      transform: scale(0.8) translateX(-50%);
      width: 22px;
    }
  `}
`

const GiftWrapItem = ({
  giftWrap,
  handleClick,
  selected,
  styleCode,
  tabIndex,
}) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <PackageItem
      aria-checked={selected}
      aria-label={`${giftWrap.package_name} gift wrap`}
      key={`item-wrap-${giftWrap.package.addon_code}`}
      onClick={handleClick}
      role="radio"
      selected={selected}
      tabIndex={tabIndex}
      {...keyboardProps}
    >
      <Image
        alt={giftWrap.package_name}
        data-cy={`cart-line-item-gift-wrap-${giftWrap.package.addon_code}-${styleCode}`}
        ratio="280 / 280"
        src={giftWrap.image_path}
      />
      <Typography fontSize="12px" letterSpacing="-0.03em" lineHeight="14px">
        {giftWrap.package_name}
      </Typography>
    </PackageItem>
  )
}

GiftWrapItem.propTypes = {
  giftWrap: PropTypes.object,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
  styleCode: PropTypes.string,
  tabIndex: PropTypes.number,
}

const GiftWraps = ({
  giftWrapPrice,
  handleGiftWrap,
  item,
  pkgOptions,
  selectedWrap,
}) => {
  const [toggleGiftWraps, setToggleGiftWraps] = useState(!!selectedWrap)
  return (
    <>
      <Checkbox
        checked={toggleGiftWraps}
        data-cy={`cart-line-item-gift-wrap-checkbox-${item.style_code}`}
        onChange={() => {
          if (toggleGiftWraps) {
            handleGiftWrap(pkgOptions[0])
            setToggleGiftWraps(false)
          } else {
            handleGiftWrap(pkgOptions[1])
            setToggleGiftWraps(true)
          }
        }}
      >
        <Box ml="6px">
          Add Gift Wrap (
          {giftWrapPrice > 0
            ? `+${to_currency(giftWrapPrice, { precision: 2 })}`
            : "FREE"}
          )
        </Box>
      </Checkbox>
      <Box
        aria-labelledby={`cart-line-item-gift-wrap-group-${item.id}`}
        ml="26px"
        role="radiogroup"
      >
        <Typography
          as="h5"
          fontSize="12px"
          fontWeight="400"
          id={`cart-line-item-gift-wrap-group-${item.id}`}
          letterSpacing="-0.03em"
          lineHeight="12px"
          mb={2}
          mt="12px"
        >
          Select a gift wrap of your choice.
        </Typography>
        <FocusScope restoreFocus>
          <PackageSlider
            data-cy={`cart-line-item-gift-wrap-${item.style_code}`}
            mb={2}
            numItems={pkgOptions.length}
          >
            {pkgOptions.slice(1).map((p, i) => {
              const selected = selectedWrap
                ? selectedWrap.addon_code === p.package.addon_code
                : p.id === "NONE"

              return (
                <GiftWrapItem
                  giftWrap={p}
                  handleClick={() => {
                    setToggleGiftWraps(true)
                    handleGiftWrap(p)
                  }}
                  key={`item-wrap-${p.package.addon_code}`}
                  selected={selected}
                  styleCode={item.style_code}
                  tabIndex={selected || (!selectedWrap && i === 0) ? 0 : -1}
                />
              )
            })}
          </PackageSlider>
        </FocusScope>
      </Box>
    </>
  )
}

GiftWraps.propTypes = {
  giftWrapPrice: PropTypes.number,
  handleGiftWrap: PropTypes.func,
  item: PropTypes.object,
  pkgOptions: PropTypes.array,
  selectedWrap: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

export default withGiftWraps(GiftWraps)
